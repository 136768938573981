import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Feature1SVG from "../../images/2.svg";
import Feature2SVG from "../../images/8.svg";
import Feature3SVG from "../../images/6.svg";
import Feature4SVG from "../../images/10.svg";
import IphoneSVG from "../../images/4.svg";
import IconSVG from "../../images/5.svg";
import Star24 from "../../icons/Star24";
import Meta from "../Meta";

import "./Main.css";
import { useDispatch } from "react-redux";
import { sendMessage } from "../Loader/actions";

const Main = () => {
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const onChangeForm = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === true) {
      dispatch(sendMessage(data));

      setValidated(false);
      setData({
        name: "",
        phone: "",
        email: "",
        message: "",
      });

      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <React.Fragment>
      <Meta pageName="main" />
      <section className="banner" id="main">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <div className="offer">
                <img src={IconSVG} alt="CarCity" className="offer__icon" />
                <h2 className="offer__title">
                  All car and driver services on one mobile app
                </h2>
                <p className="offer__description">
                  Find the best price for servicing your car and sign up for a
                  service center or car wash with a convenient location in your
                  city. All automotive services and technical centers in Dubai
                  are collected in one application.
                </p>
                <div className="offer__buttons">
                  <Button
                    type="primary"
                    className="offer__button"
                    onClick={() => {}}
                    size="lg"
                    href="https://apps.apple.com/ru/app/carcity-uae/id6443445373"
                  >
                    Join Carcity Free
                  </Button>
                  <Button
                    type="primary"
                    className="offer__button"
                    onClick={() => {}}
                    size="lg"
                    variant="outline-primary"
                    href="https://carcity.ae/Carcity.pdf"
                  >
                    Get Briff
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {}}
                    size="lg"
                    href="#contact"
                    variant="outline-primary"
                  >
                    Contact Us
                  </Button>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <img src={IphoneSVG} alt="iphone" className="banner__iphone" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="reviews">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div className="sectionHead">
                <h3 className="sectionHead__title">Just an honest opinion</h3>
                <p className="sectionHead__description">
                  Our application contains real reviews of living people. Which
                  were left not only with us, but also on other sites on the
                  Internet.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4} lg={4} xl={4} xxl={4}>
              <div className="review review--first">
                <div className="review__stars">
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                </div>
                <h6 className="review__title">Muthu Raj</h6>
                <p className="review__text">
                  Royal Swiss Auto Service is very good. Expecially Oumaima's
                  service was very humble and polite, I am very thankful to her.
                  I have bought a new GAC GS8 car and I have given it to them
                  for polish, I was astonished by the results, it looked even
                  more better. Very impressed with Oumaima's response and
                  service. I would recommend Royal Swiss Auto Service to all my
                  friends.
                </p>
                <span className="review__organization">
                  Royal Swiss Auto Sevrice
                </span>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} xl={4} xxl={4}>
              <div className="review  review--second">
                <div className="review__stars">
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#B3D7FF" />
                </div>
                <h6 className="review__title">Rahime Juma</h6>
                <p className="review__text">
                  Sayed did an amazing manual wash. Always leave here with an
                  immaculately detailed car. Come with time, as the process is
                  thorough and therefore not the quickest. Shampooing the engine
                  is great after time in the desert or mountains. Whether you
                  choose the manual or machine wash, the drying and interior is
                  all by hand and always a great job. Prices are also extremely
                  reasonable. Highly recommended.
                </p>
                <span className="review__organization">ProWash - 1060</span>
              </div>
            </Col>
            <Col sm={12} md={4} lg={4} xl={4} xxl={4}>
              <div className="review">
                <div className="review__stars">
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                  <Star24 color="#007AFF" />
                </div>
                <h6 className="review__title">Sakthi Doss</h6>
                <p className="review__text">
                  Ac for my Porsche cayenne was not working which they fixed for
                  very less price and they did very good job. I am very
                  satisfied with their work and how they communicate to customer
                  regarding the repairs and quotations. And best thing about
                  them is they will try to find the quality and cheap options
                  for your car.
                </p>
                <span className="review__organization">ORANGE Auto</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="feature1" id="features">
        <Container>
          <Row>
            <Col
              sm={12}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              className="justify-content-md-center"
            >
              <div className="feature">
                <h3 className="feature__title">
                  Choose an organization based on location, rating and cost of
                  services
                </h3>
                <p className="feature__description">
                  We plan to connect absolutely all technical car service
                  centers in the United Arab Emirates to our service. This will
                  create a transparent automated market, the simplicity and
                  convenience of providing car maintenance services.
                </p>
                <a href="#contact" className="feature__link">
                  try it now
                </a>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <img
                src={Feature1SVG}
                alt="carcity-feature"
                className="feature__image"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="feature2">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <div className="feature">
                <h3 className="feature__title">
                  All information can be found on the organization card
                </h3>
                <p className="feature__description">
                  You can see honest reviews and the organization's actual
                  rating. Inside you will find a complete list of services and
                  prices of the technical center, opening hours and all contact
                  details.
                </p>
                <a href="#contact" className="feature__link">
                  try it now
                </a>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <img
                src={Feature2SVG}
                alt="carcity-feature"
                className="feature__image"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="feature2">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <div className="feature">
                <h3 className="feature__title">
                  Submit a service request in 2 clicks
                </h3>
                <p className="feature__description">
                  An elementary and user-friendly interface will help you create
                  a request for technical assistance and sign up for a specific
                  day and time to save your time.
                </p>
                <a href="#contact" className="feature__link">
                  try it now
                </a>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <img
                src={Feature3SVG}
                alt="carcity-feature"
                className="feature__image"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="feature3">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <div className="feature">
                <h3 className="feature__title">
                  The history of your car is stored as a medical history
                </h3>
                <p className="feature__description">
                  The history of entries will help not only track the status of
                  applications and future entries, but also take into account
                  past appeals and incidents with your iron comrade.
                </p>
                <a href="#contact" className="feature__link">
                  try it now
                </a>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <img
                src={Feature4SVG}
                alt="carcity-feature"
                className="feature__image"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="contact" id="contact">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm={12} md={8} lg={8} xl={8} xxl={8}>
              <div className="sectionHead">
                <h3 className="sectionHead__title">Contact us</h3>
                <p className="sectionHead__description">
                  We are glad to any cooperation with you, so you can write to
                  us on any topic and for any reason. We will definitely answer
                  you as soon as possible.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <div className="details">
                <div className="details__item">
                  <h4>Location</h4>
                  <span>UAE, Dubai</span>
                </div>
                <div className="details__item">
                  <h4>Telegram</h4>
                  <span>@frontend_max</span>
                </div>
                <div className="details__item">
                  <h4>Email</h4>
                  <span>info@carcity.ae</span>
                </div>
                <div className="details__item">
                  <h4>Phone</h4>
                  <span>+7 (929) 587-02-88</span>
                </div>
                <div className="details__item">
                  <h4>Facebook</h4>
                  <span>Maksim Zaycev</span>
                </div>
                <div className="details__item">
                  <h4>Instagram</h4>
                  <span>@zaycev_max</span>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Form
                className="form"
                noValidate
                validated={validated}
                onSubmit={onSubmit}
              >
                <FloatingLabel
                  controlId="floatingName"
                  label="Name*"
                  className="mb-3"
                >
                  <Form.Control
                    value={data.name}
                    name="name"
                    type="name"
                    placeholder="Name*"
                    onChange={onChangeForm}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your phone name
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingPhone"
                  label="Phone*"
                  className="mb-3"
                >
                  <Form.Control
                    value={data.phone}
                    name="phone"
                    type="phone"
                    placeholder="Phone*"
                    onChange={onChangeForm}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your phone number
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingEmail"
                  label="Email*"
                  isInvalid
                  className="mb-3"
                >
                  <Form.Control
                    value={data.email}
                    name="email"
                    type="email"
                    placeholder="Email*"
                    onChange={onChangeForm}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your email
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingMessage"
                  label="Message*"
                  className="pb-3"
                  style={{ borderBottom: "1px solid #D9D9D9" }}
                >
                  <Form.Control
                    value={data.message}
                    name="message"
                    type="message"
                    as="textarea"
                    placeholder="Message*"
                    style={{ height: "100px" }}
                    onChange={onChangeForm}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please type your message
                  </Form.Control.Feedback>
                </FloatingLabel>
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3"
                  size="lg"
                >
                  Send message
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer">
        <Container>
          <Row className="justify-content-center">
            <Col sm={12} md={2} lg={2} xl={2} xxl={2}>
              <div className="footer__label">
                carcity.ae | <a href="/privacy">Privacy Policy</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Main;
