import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';

// Components
import Head from '../features/Head';
import Loader from '../features/Loader';

// Pages
import Main from "../features/Main";
import Privacy from "../features/Privacy";

const App = () => (
	<Fragment>
		<Loader />
		<div className="layout" >
			<Head />
			<Switch>
				<Route path="/" exact><Main /></Route>
				<Route path="/privacy" exact><Privacy /></Route>
			</Switch>
		</div>
	</Fragment>
);

export default App;
