export const MOCK_API_PATIENTS_URL = 'https://5ed2b4d9717d5f00165183c1.mockapi.io/patients';
export const COVID_API_URL = 'https://api.covid19api.com/all';
export const REVIEWS_URL = 'https://5ed2b4d9717d5f00165183c1.mockapi.io/reviews';
export const CITIES = [
	"Москва",
	"Новосибирск",
	"Санкт-Петербург",
	"Екатеринбург",
	"Челябинск",
	"Омск",
	"Нижний-Новгород",
	"Казань",
	"Ростов-на-Дону"
];

export const profileData = {
	name: 'Максим Зайцев',
	position: 'Senior frontend разработчик',
	description: 'Главный инженер разработки в СБЕР',
	location: 'Россия, Москва',
	age: '26 лет',
	experience: '9 лет опыта',
	tags: [
		{
			id: '0',
			value: 'html',
			color: 'blue'
		},
		{
			id: '1',
			value: 'css',
			color: 'blue'
		},
		{
			id: '2',
			value: 'javascript',
			color: 'blue'
		},
		{
			id: '3',
			value: 'reactjs',
			color: 'blue'
		},
		{
			id: '4',
			value: 'redux',
			color: 'blue'
		},
		{
			id: '9',
			value: 'webpack',
			color: 'blue'
		},
		{
			id: '10',
			value: 'materialUI',
			color: 'blue'
		},
		{
			id: '5',
			value: 'antdesign',
			color: 'blue'
		},
		{
			id: '6',
			value: 'git',
			color: 'blue'
		},
		{
			id: '7',
			value: 'figma',
			color: 'blue'
		},
		{
			id: '8',
			value: 'photoshop',
			color: 'blue'
		}
	]
};

export const navigation = [
	{
		key: 'main',
		title: 'App',
	},
	{
		key: 'features',
		title: 'Features',
	},
	{
		key: 'contact',
		title: 'Contact',
	},
];

export const blackColor = "#000";
export const darkColor = "#3D4F67";
export const primaryColor = "#007AFF";
export const secondaryColor = "#E6F1FC";
export const greyMediumColor = "#AAABAD";