import * as React from "react"

const Star24 = ({ color }) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2a1 1 0 0 1 .918.604l2.47 5.734 6.215.576a1 1 0 0 1 .567 1.747l-4.69 4.12 1.373 6.09a1 1 0 0 1-1.486 1.079L12 18.763 6.633 21.95a1 1 0 0 1-1.487-1.08l1.373-6.09-4.69-4.119a1 1 0 0 1 .568-1.747l6.215-.576 2.47-5.734A1 1 0 0 1 12 2Z"
      fill={color}
    />
  </svg>
)

export default Star24;