import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logotype from '../../images/logotype.svg';
import './Head.css';
import Navigation from '../Navigation';

const Head = () => (
	<section className="head">
		<Container>
			<Row>
				<Col sm={4} md={3} lg={3} xl={3} xxl={3}>
					<div className="head__logotype">
						<img src={Logotype} alt="CARCITY.ae" />
					</div>
				</Col>
				<Col sm={8} md={9} lg={9} xl={9} xxl={9}>
					<Navigation />
				</Col>
			</Row>
		</Container>
	</section>
);

export default Head;