import {
	TOGGLE_VISIBLE_LOADER
} from "../../store/types";

const initialState = {
	isVisible: false
};

export default (state = initialState, action) => {
	if (action.type === TOGGLE_VISIBLE_LOADER) return ({ isVisible: !state.isVisible });

	return state;
}