export const metaData = {
  main: {
    title: "CARCITY | All car and driver services on one mobile app",
    description:
      "Find the best price for servicing your car and sign up for a service center or car wash with a convenient location in your city. All automotive services and technical centers in Dubai are collected in one application.",
    keywords:
      "Мобильные приложения, разработка под ключ, разработать мобильное приложение, автооматизация бизнес-процессов, приложение под ключ, разработка платформ и приложений",
  },
  privacy: {
    title: "CARCITY | All car and driver services on one mobile app",
    description:
      "Find the best price for servicing your car and sign up for a service center or car wash with a convenient location in your city. All automotive services and technical centers in Dubai are collected in one application.",
    keywords:
      "Мобильные приложения, разработка под ключ, разработать мобильное приложение, автооматизация бизнес-процессов, приложение под ключ, разработка платформ и приложений",
  },
};
