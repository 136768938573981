import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { navigation } from "../../consts";
import "./Navigation.css";

const Navigation = () => {
  const { pathname } = useLocation();
  const [currentRoot, setCurrentRoot] = useState(navigation[0].key);

  const onHandleClick = (key) => {
    setCurrentRoot(key);
  };

  return (
    <div className="navigation">
      {pathname === "/" ? (
        <>
          {navigation.map((item) => (
            <a
              className={`navigation__item ${
                item.key === currentRoot
                  ? "navigation__item--selected"
                  : "navigation__item--normal"
              }`}
              onClick={() => onHandleClick(item.key)}
              href={`#${item.key}`}
            >
              {item.title}
            </a>
          ))}
        </>
      ) : (
        <a
          className="navigation__item navigation__item--normal"
          href="/"
          onClick={() => onHandleClick("/main")}
        >
          back
        </a>
      )}
    </div>
  );
};

export default Navigation;
