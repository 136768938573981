import React from 'react';
import Helmet from 'react-helmet';
import { metaData } from '../../meta';

const Meta = ({ pageName }) => (
	<Helmet>
		<title>{metaData[pageName].title}</title>
		<meta name="description" content={metaData[pageName].description} />
		<meta name="keywords" content={metaData[pageName].keywords} />
	</Helmet>
);

export default Meta;
