import React from 'react';
import { connect } from 'react-redux';
import './Loader.css';

const Loader = ({ isVisible }) => {
	if (isVisible) {
		return (
			<div className="modal-backdrop">
				<span className="spinner"></span>
			</div>
		);
	} else return null;
}

const mapStateToProps = state => ({
	isVisible: state.loading.isVisible,
});

export default connect(mapStateToProps, null)(Loader);