import * as db from '../../api/db';
import { TOGGLE_VISIBLE_LOADER } from '../../store/types';

export const toggleVisibleLoader = () => ({ type: TOGGLE_VISIBLE_LOADER });

export const sendMessage = newMessage => dispatch => {
	dispatch({ type: TOGGLE_VISIBLE_LOADER });
	return db.sendMessage(newMessage)
		.then(() => dispatch({
			type: TOGGLE_VISIBLE_LOADER
		}));
};