export const TOGGLE_CREATE_GOAL_MODAL = 'CREATE_GOAL_MODAL/TOGGLE_VIEW';

export const FETCH_REVIEWS = 'REVIEWS/FETCH_REVIEWS';
export const ADD_REVIEW = 'REVIEWS/ADD_REVIEW';
export const EDIT_REVIEW = 'REVIEWS/EDIT_REVIEW';
export const SHOW_ADDING_REVIEW = 'REVIEWS/SHOW_ADDING_REVIEW';
export const HIDE_ADDING_REVIEW = 'REVIEWS/HIDE_ADDING_REVIEW';

export const SHOW_PROJECT_DESCRIPTION = 'PROJECT_DESCRIPTION/SHOW_PROJECT_DESCRIPTION';
export const HIDE_PROJECT_DESCRIPTION = 'PROJECT_DESCRIPTION/HIDE_PROJECT_DESCRIPTION';

export const FETCH_PROJECTS = 'REVIEWS/FETCH_PROJECTS';

export const FETCH_COMAND = 'REVIEWS/FETCH_COMAND';

export const TOGGLE_VISIBLE_LOADER = 'LOADER/TOGGLE_VISIBLE_LOADER';

export const TOGGLE_VISIBLE_NAV = 'NAV/TOGGLE_VISIBLE_NAV';

export const TOGGLE_VISIBLE_GET_CONTACT = 'GET_CONTACT/TOGGLE_VISIBLE_GET_CONTACT';